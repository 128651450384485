<template>
  <div>
      Processing...
  </div>
</template>

<script>
import { auth } from '../schmucklicloud'
export default {
    async mounted() {
        var token = window.location.href.split("?")[1].replace("token=", "");
        var response = await auth.activateUser(token);
        if (response.isOK) {
            this.$notify.toast("Activated");
        } else {
            this.$notify.toast(response.message);
        }
        this.$router.replace("/");
    }
}
</script>

<style>
</style>